<template>
  <section class="section">
    <div class="content">
      <b-loading :is-full-page="false" v-model="isLoading"></b-loading>

      <div class="mb-4">
        <div class="buttons" v-if="isSdiEinvoice">
          <button
            v-for="button in einvoiceActions"
            :key="`button_${button.action}`"
            class="button is-primary"
            @click="doEinvoiceAction(button)"
          >
            <b-icon :icon="$config.icons.buttons[`${button.label}`]"></b-icon>
            <span>{{ $t(`einvoice.buttons.${button.label}`) }}</span>
          </button>
          <button
            v-if="classId >= 0 && isCreditEinvoice && isSdiEinvoice"
            type="button"
            class="button is-primary"
            @click="createSdIEInvoice"
          >
            <b-icon :icon="$config.icons.buttons.new_sdi_einvoice"></b-icon>
            <span>{{ $t("buttons.new_sdi_einvoice") }}</span>
          </button>
        </div>
      </div>
      <div class="level" style="flex-wrap: wrap">
        <div class="level-left mb-2">
          <div class="level-item">
            <div class="buttons">
              <!-- Validation button -->
              <b-tooltip
                :type="validated ? 'is-success' : 'is-danger'"
                :active="showValidationPopup"
                :label="validationResultMsg"
                class="move-right"
                size="is-small"
                multilined
                always
                animated
              >
                <template #content> </template>
                <!-- Can be disabled adding :disabled="validated" to button -->
                <button
                  class="button is-primary"
                  @click="validateDocument"
                  v-if="buttonsDocSigned"
                >
                  <b-icon icon="text-box-check"></b-icon>
                  <span>{{ $t("buttons.validate_document") }}</span>
                </button>
              </b-tooltip>

              <!-- Download button -->
              <button
                class="button is-primary"
                :disabled="!validated"
                @click="displayDownloadDialog = true"
                v-if="buttonsDocSigned"
              >
                <b-icon icon="download"></b-icon>
                <span>{{ $t("buttons.download") }}</span>
              </button>
              <button
                class="button is-primary"
                @click="download([1])"
                v-if="!buttonsDocSigned"
              >
                <b-icon icon="download"></b-icon>
                <span>{{ $t("buttons.download") }}</span>
              </button>
              <button class="button is-primary" @click="displayOriginal">
                <b-icon icon="file-document"></b-icon>
                <span>{{ $t("buttons.open_original_document") }}</span>
              </button>
              <!-- <button
                class="button is-primary"
                @click="displaySignatureTimeDialog = true"
                :disabled="!validated || !signatureTime"
                v-if="buttonsDocSigned"
              >
                <b-icon icon="clock"></b-icon>
                <span>{{ $t("buttons.show_signature_timing") }}</span>
              </button> -->
            </div>
          </div>
        </div>
        <div class="level-right mb-2">
          <div class="level-item">
            <button
              type="button"
              class="button is-primary"
              @click="displayDataFilterDialog = true"
            >
              <b-icon icon="glasses"></b-icon>
              <span>{{
                isMobile
                  ? $t("document.detail.mobile.buttons.filter_data")
                  : $t("document.detail.buttons.filter_data")
              }}</span>
            </button>
          </div>
        </div>
      </div>

      <div v-if="validationData" class="columns">
        <div
          v-for="(v, index) in validationData"
          :key="index"
          class="column is-half mb-0"
        >
          <validation-result :data="v" class="box">
            <template #header>
              <div class="mb-3 has-text-centered is-size-4">
                Tipologia {{ index + 1 }}
              </div>
            </template>
          </validation-result>
        </div>
      </div>

      <DossierSelect
        v-if="
          !status.isLoadingDetails &&
          details.dossiers &&
          details.dossiers.length > 0
        "
        :dossiers="details.dossiers"
        @show-dossier="openDossier"
      >
      </DossierSelect>

      <div
        v-if="!status.isLoadingDetails"
        class="box has-background-eurotext-light has-text-white-bis"
      >
        <!-- <b-loading :is-full-page="false" v-model="isLoading"></b-loading> -->

        <b-field
          v-for="(prop, index) in displayedProperties"
          :key="index"
          v-show="prop.visible"
          :label="prop.description"
          custom-class="has-text-white-bis"
          horizontal
        >
          <b-input
            :value="String(details.properties[prop.name])"
            custom-class="cursor-default"
            disabled
            expanded
          ></b-input>
        </b-field>
      </div>
    </div>

    <b-modal
      :active.sync="displayDownloadDialog"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
    >
      <template #default>
        <modal-type-selection
          :downloadTypes="downloadTypes"
          @download="download"
        ></modal-type-selection>
      </template>
    </b-modal>

    <b-modal
      :active.sync="displayDataFilterDialog"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
    >
      <template #default>
        <TableColumnFilter
          :columns="displayedDataFilterColumn"
          :data="displayedProperties"
          @order-change="onOrderChange"
          @toggleVisibility="ontoggleVisibility"
          @toggleVisibilityAll="ontoggleVisibilityAll"
        >
          <template #header>
            <p class="modal-card-title is-size-5">
              {{
                isMobile
                  ? $t("table.col-options.mobile.header")
                  : $t("table.col-options.header")
              }}
            </p>
          </template>
          <template #beforeTable>
            <p class="is-size-5">
              {{
                isMobile
                  ? $t("table.col-options.mobile.col-filter-title")
                  : $t("table.col-options.col-filter-title")
              }}
            </p>
          </template>
        </TableColumnFilter>
      </template>
    </b-modal>

    <!-- <b-modal
      v-if="signatureTime"
      :active.sync="displaySignatureTimeDialog"
      has-modal-card
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
    >
      <template #default>
        <SignatureTimeDisplay
          :timestamp="signatureTime.timestamp"
          :signatures="signatureTime.signatures"
        ></SignatureTimeDisplay>
      </template>
    </b-modal> -->
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import ModalTypeSelection from "@/components/ModalTypeSelection";
import TableColumnFilter from "@/components/DocumentTable/TableColumnFilter";
// import SignatureTimeDisplay from "@/components/SignatureTimeDisplay";
import DossierSelect from "@/components/Dossiers/DossierSelect";
import { uaMixin } from "@/mixins/user-agent";
import {
  KUrls,
  KPropNames,
  KDocumentDownloadType,
  getDocumentDownloadType,
} from "@/helpers/constants";
import {
  classService,
  downloadService,
  validationService,
  inputService,
  errorResponseSchema,
} from "@/services";
import { searchService } from "../services";
import ValidationResult from "./ValidationResult.vue";
export default {
  // name: "DocumentDetail",
  components: {
    ModalTypeSelection,
    TableColumnFilter,
    // SignatureTimeDisplay,
    DossierSelect,
    ValidationResult,
  },
  mixins: [uaMixin],
  props: {
    companySchema: {
      type: String,
      required: true,
    },
    classId: {
      required: false,
      default: -1,
    },
    documentId: {
      required: true,
    },
    properties: {
      type: Array,
      required: false,
      default: () => [],
    },
    isHistory: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    stateKey: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      localProperties: [...this.properties],
      details: {},
      status: {
        isLoadingDetails: false,
        isDownloading: false,
        isValidating: false,
      },
      filterList: [],
      displayedDataFilterColumn: [],
      orderedPropertiesNames: null,
      visiblePropertiesNames: null,
      displayDownloadDialog: false,
      displayDataFilterDialog: false,
      // displaySignatureTimeDialog: false,
      // downloadTypes: [],
      validated: false,
      showValidationPopup: false,
      validationResultMsg: "",
      validationData: null,
      // signatureTime: null,
      einvoiceActions: [
        { action: "DOWNLOAD", label: "DOWNLOAD_FILES_SDI" },
        { action: "EXPORT_AS_PDF", label: "EXPORT_AS_PDF" },
      ],
    };
  },
  created() {
    // Assign document id to correspondig property in order to be able to filter it.
    this.filterList = this.properties.map((prop) => {
      const newProp = { ...prop };
      if (newProp.name === KPropNames.id) {
        newProp.value = this.documentId;
      } else {
        newProp.value = "";
      }
      return newProp;
    });
    this.fetchDocumentDetails(); // THIS IS ASYNC!

    this.displayedDataFilterColumn = [
      {
        field: "description",
        label: this.$t("document.detail.filter_column_label"),
      },
    ];
  },
  watch: {
    properties: function (newValue) {
      this.localProperties === [...newValue];
    },
  },
  computed: {
    ...mapGetters({
      getClassNameById: "menu/getClassNameById",
      getClassById: "menu/getClassById",
    }),
    className: function () {
      return this.getClassNameById(this.classId, this.companySchema);
    },
    /**
     * Return true if the document class is a credit (active) one.
     * If no classId is provided (classId < 0), returns false.
     */
    isCreditEinvoice: function () {
      if (this.classId >= 0)
        return this.getClassById(this.classId, this.companySchema)
          .isCreditInvoice;
      else return false;
    },
    isLoading: function () {
      for (const key of Object.keys(this.status)) {
        if (this.status[key]) {
          return true;
        }
      }
      return false;
    },
    /**
     * Whether this document is a sdi einvoice or not.
     * If no document detail or document detail's properties are present returns false;
     */
    isSdiEinvoice: function () {
      if (this.details && this.details.properties) {
        return (
          this.details.properties["is_sdi_einvoice"] ||
          this.details.properties["is_sdi_einvoice"] === "true"
        );
      } else {
        return false;
      }
    },
    /**
     * Whether is a signed document or not. Beacuse not every document in history is signed. eg. SdI notifications
     */
    isSigned: function () {
      if (this.details && this.details.properties) {
        let props = this.details.properties;
        for (let propKey of Object.keys(props)) {
          // If the document has a signature or timestamp return true.
          if (
            propKey === "signed_file_alt" ||
            propKey === "signed_file" ||
            propKey === "timestamp_file"
          ) {
            console.log(propKey);
            if (props[propKey]) {
              console.log(props[propKey]);
              return true;
            }
          } else if (propKey === "eidas_sign_type") {
            // eidas_sign_type empty or with value NoneF
            console.log(propKey);
            if (props[propKey] && props[propKey] !== "NoneF") {
              console.log(props[propKey]);
              return true;
            }
          }
        }
      }
      return false;
    },
    buttonsDocSigned: function () {
      return this.mIsHistory && this.isSigned;
    },
    mIsHistory: function () {
      if (this.isHistory === undefined)
        return this.$route.path.includes(KUrls.routes.SEARCH);
      else return this.isHistory;
    },
    mProps: function () {
      return this.localProperties.map((prop) => {
        return {
          name: prop.name,
          description: this.$t(`docclass-properties.${prop.description}`),
          type: prop.type ? prop.type : "alfanumerico",
          visible: true,
        };
      });
    },
    mStateKey: function () {
      if (this.stateKey) {
        return `dd.${this.stateKey}`;
      } else {
        return `dd.${this.companySchema}.${this.classId}`;
      }
    },
    displayedProperties: function () {
      const orderedProps = [];
      // Add the properties in the saved order
      if (this.orderedPropertiesNames) {
        for (let i = 0; i < this.orderedPropertiesNames.length; i++) {
          let op = this.orderedPropertiesNames[i];
          let foundProp = this.mProps.find((prop) => prop.name === op);
          if (foundProp) orderedProps.push({ ...foundProp });
        }
      }
      const allOrdered = [...orderedProps];
      // Add all the properties that are not stored in the saved order
      for (let i = 0; i < this.mProps.length; i++) {
        if (
          orderedProps.findIndex((prop) => prop.name === this.mProps[i].name) <
          0
        ) {
          allOrdered.push({ ...this.mProps[i] });
        }
      }
      // Toggle visibility acording to the saved list of visible properties
      if (this.visiblePropertiesNames) {
        allOrdered.forEach((obj) => {
          const visible = this.visiblePropertiesNames.indexOf(obj.name) !== -1;
          obj.visible = visible;
        });
      }
      return allOrdered;
    },
    downloadTypes: function () {
      const dTypes = getDocumentDownloadType();
      dTypes.find((type) => type.key === "ENVELOPE").disabled = true;
      if (this.details && this.details.properties) {
        let props = this.details.properties;
        for (let propKey of Object.keys(props)) {
          switch (propKey) {
            case "signed_file_alt":
              if (!props[propKey])
                dTypes.find(
                  (type) => type.key === "SIGNED_FILE_ALT"
                ).disabled = true;
              break;
            case "signed_file":
              if (!props[propKey]) {
                dTypes.find((type) => type.key === "SIGNATURE").disabled = true;
                dTypes.find(
                  (type) => type.key === "SIGNING_CERTIFICATE"
                ).disabled = true;
              } else {
                dTypes.find((type) => type.key === "ENVELOPE").disabled = false;
              }
              break;
            case "timestamp_file":
              if (!props[propKey]) {
                dTypes.find((type) => type.key === "TIMESTAMP").disabled = true;
              } else {
                dTypes.find((type) => type.key === "ENVELOPE").disabled = false;
              }
              break;
          }
          // TODO: add others cases
        }
      }
      return dTypes;
    },
  },
  methods: {
    closeDownloadDialog() {
      this.displayDownloadDialog = false;
    },
    checkDownloadTypeVisibility() {},
    displayOriginal() {
      var artifacts = KDocumentDownloadType.ORIGINAL_FILE;
      const ids = [this.documentId];
      // const isHistory = this.mIsHistory;
      this.status.isDownloading = true;
      downloadService
        .downloadDocuments(
          this.$route.params.company,
          this.$i18n.locale,
          "ddMMyyyy",
          true,
          ids,
          artifacts,
          [],
          true
        )
        .then((data) => {
          let url = data.display.url;
          const fileName = data.display["fileName"];
          const tab = {
            id: `${fileName}`,
            component: "IFrame",
            label: `${fileName}`,
            icon: "",
            props: {
              url: url,
              mimeType: data.display["mimeType"],
            },
          };
          this.$store.dispatch("tabs/openTab", tab);
        })
        .finally(() => (this.status.isDownloading = false));
    },
    createSdIEInvoice() {
      inputService
        .createSdIEInvoice(
          this.companySchema,
          this.className,
          this.details.properties["document_id"]
        )
        .then();
    },
    doEinvoiceAction(button) {
      this.status.isDownloading = true;
      const payload = {
        docId: this.details.properties["document_id"],
        action: button.action,
      };
      classService
        .doEInvoiceAction(this.companySchema, payload)
        .then((data) => {
          if (data) {
            if (data.errors) {
              this.errorsNS = data.errors;
              this.displayErrorNSDialog = true;
            }
            if (button.action === "SHOW" || button.action === "EXPORT_AS_PDF") {
              if (data.url) {
                let url = data.url;
                const fileName = data["fileName"];
                const tab = {
                  id: `${fileName}`,
                  component: "IFrame",
                  label: `${fileName}`,
                  icon: "",
                  props: {
                    url: url,
                    mimeType: data["mimeType"],
                  },
                };
                this.$store.dispatch("tabs/openTab", tab);
              } else {
                console.error("No url returned to open in iframe");
              }
            }
          }
        })
        .finally(() => (this.status.isDownloading = false));
    },
    download(payload) {
      this.closeDownloadDialog();
      this.status.isDownloading = true;
      var artifacts = 0;
      for (const type of payload) {
        artifacts += type;
      }
      const ids = [this.documentId];
      downloadService
        .downloadDocuments(
          this.$route.params.company,
          this.$i18n.locale,
          "ddMMyyyy",
          true,
          ids,
          artifacts,
          []
        )
        .then((data) => {
          console.log(data);
        })
        .finally(() => (this.status.isDownloading = false));
    },
    fetchDocumentDetails: function () {
      this.status.isLoadingDetails = true;
      const company = this.companySchema;
      const classId = this.classId;
      const docId = this.documentId;
      // const list = this.filterList;
      // const isHistory = this.mIsHistory;

      // return classService
      //   .fetchDocumentHistory(company, classId, list, 1, 1, isHistory)
      //   .then((res) => {
      //     this.details = res.documents[0];
      //   })
      //   .finally(() => (this.status.isLoadingDetails = false));

      return searchService
        .fetchDocumentDetails(company, classId, docId)
        .then((res) => {
          if (!this.localProperties || this.localProperties.length === 0) {
            this.localProperties = res["properties"];
          }
          this.details = res["documents"][0];
        })
        .finally(() => (this.status.isLoadingDetails = false));
    },
    onOrderChange(newData) {
      this.orderedPropertiesNames = newData.map((data) => data.name);
      this.storeState();
    },
    ontoggleVisibility(visibleObjects) {
      this.visiblePropertiesNames = visibleObjects.map((obj) => obj.name);
      this.storeState();
    },
    ontoggleVisibilityAll(visibleObjects) {
      this.visiblePropertiesNames = visibleObjects.map((obj) => obj.name);
      this.storeState();
    },
    openDossier(dossierId) {
      const tab = {
        id: dossierId,
        component: "DossierDetail",
        label: `${dossierId}`,
        icon: "folder",
        props: {
          companyName: this.companySchema,
          dossierId: dossierId,
          stateKey: this.stateKey,
        },
      };
      this.$store.dispatch("tabs/openTab", tab);
    },
    storeState() {
      let state = {};
      state.order = this.orderedPropertiesNames;
      state.visible = this.visiblePropertiesNames;
      if (Object.keys(state).length) {
        localStorage.setItem(this.mStateKey, JSON.stringify(state));
      }
    },
    validateDocument: function () {
      this.status.isValidating = true;
      validationService
        .validateDocument(this.companySchema, this.documentId)
        .then((data) => {
          if (data.success) {
            this.validationData = data.data;
            this.validated = true;
            // this.validationResultMsg = this.$t(
            //   "document.validation.success.message"
            // );
            // this.$buefy.toast.open({
            //   message: this.$t("document.validation.success.message"),
            //   type: "is-success",
            //   duration: 4000,
            // });
            // this.signatureTime = data.data;
          } else {
            const code = data.data[errorResponseSchema.code];
            this.validationResultMsg = this.$t(
              `document.validation.errors.${code}`
            );
            // const i18nMessage = this.$t(`document.validation.errors.${code}`);
            // this.$buefy.toast.open({
            //   duration: 5000,
            //   message: i18nMessage,
            //   position: "is-top",
            //   type: "is-danger",
            // });
          }
          // this.showValidationPopup = true;
          // setTimeout(() => (this.showValidationPopup = false), 4000);
        })
        .finally(() => {
          this.status.isValidating = false;
        });
    },
    async validateDocumentEU() {
      try {
        this.status.isValidating = true;
        let signedAlt = false;
        if (this.details && this.details.properties) {
          let props = this.details.properties;
          signedAlt = !!props["signed_file_alt"];
        }
        const res = await validationService.validateDocumentEU(
          this.companySchema,
          this.documentId,
          signedAlt
        );
        this.validationData = res;
        this.validated = true;
      } catch (e) {
        console.error(e);
      } finally {
        this.status.isValidating = false;
      }
    },
  },
  beforeMount() {
    const stateString = localStorage.getItem(this.mStateKey);
    if (stateString) {
      const restoredState = JSON.parse(stateString);
      this.orderedPropertiesNames = restoredState.order;
      this.visiblePropertiesNames = restoredState.visible;
    }
  },
  mounted() {
    // this.downloadTypes = getDocumentDownloadType();
    // for (const type of this.downloadTypes) {
    //   if (type.key !== "ORIGINAL_FILE") {
    //     type.disabled = true;
    //   }
    // }
    // for (const filter of filterList) {
    //   if (filter.name === "signed_file") {
    //   }
    // }
    // this.filterList.find((filter) => filter.name === "signed_file");
  },
};
</script>

<style></style>
